// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a user loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"8g9DFIoMiufbldIbFxt3M"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from '@sentry/nextjs'

if (process.env.ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'production',

    // Enable automatic session tracking
    autoSessionTracking: true,

    // Sampling rates
    tracesSampleRate:
      process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' ? 0.2 : 1.0,
    replaysSessionSampleRate:
      process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' ? 0.2 : 1.0,
    replaysOnErrorSampleRate: 1.0,

    // Integrations
    integrations: [Sentry.replayIntegration()],

    debug: false,
  })
}
